import classNames from "classnames";

import { useCallback, useMemo, useState, useRef } from "react";

import { prep_nodes, group_smallest } from "components/TreeMap/dataUtil";

import { useWindowWidth } from "utils";

import { TreeMap } from "./TreeMap";
import { HierarchyNode } from "./util";

export interface TreeMapProps {
  data: HierarchyNode;
  colorForNode: (HierarchyNode) => string;
  tooltipHtmlForNode: (HierarchyNode) => string;
  htmlForNode: (node: HierarchyNode, width: number, height: number) => string;
  onLeafClick?(node: HierarchyNode): void;
}

export default function WrappedTreeMap({
  data,
  colorForNode,
  tooltipHtmlForNode,
  htmlForNode,
  onLeafClick,
}: TreeMapProps) {
  const windowWidth = useWindowWidth();
  const treeMapRef = useRef();

  const vizHeight = useMemo(() => {
    let idealHeight = windowWidth / 2;
    if (idealHeight < 500) {
      idealHeight = 500;
    }
    if (idealHeight > 750) {
      idealHeight = 750;
    }
    return idealHeight + Math.random();
    // Hacky: want to force a re-render, so get a new value each time windowWidth changes
  }, [windowWidth]);

  const [path, setPath] = useState<HierarchyNode[]>([]);

  const nodeRender = useMemo(
    () => getNodeRenderFunc(htmlForNode),
    [htmlForNode]
  );

  const onZoomIn = useCallback(
    node => {
      setPath(treeMapRef.current.getPath().filter(({ id }) => id !== "root"));
    },
    [setPath]
  );

  const tooltipRenderFunc = useCallback(
    function tooltipRender(sel) {
      sel.html(function ({ data }) {
        return tooltipHtmlForNode(data);
      });
    },
    [tooltipHtmlForNode]
  );

  const onZoomOut = useCallback(() => {
    setPath(path.slice(0, -1));
    treeMapRef.current.zoomOut();
  }, [treeMapRef, path]);

  const onNodeClick = useCallback(
    nodeData => {
      if (onLeafClick && !nodeData.children?.length) {
        onLeafClick(nodeData);
      }
    },
    [onLeafClick]
  );

  return (
    <div>
      <div className="d-flex justify-content-between align-items-baseline">
        <div> {path.map(node => node.name).join(" > ")}</div>
        <button
          className={classNames(
            "btn btn-sm btn-primary",
            !path.length && "d-none"
          )}
          onClick={onZoomOut}
        >
          {" "}
          {tdt("Zoom out")}
        </button>
      </div>
      <TreeMap
        onZoomIn={onZoomIn}
        data={data}
        colorScale={colorForNode}
        tooltip_render={tooltipRenderFunc}
        node_render={nodeRender}
        viz_height={vizHeight}
        ref={treeMapRef}
        onClick={onNodeClick}
      />
    </div>
  );
}

//most of the following should be genricized

function get_node_size(node) {
  if (node.offsetHeight <= 30 || node.offsetWidth <= 50) {
    return "tiny";
  } else if (node.offsetHeight < 100 || node.offsetWidth < 150) {
    return "small";
  } else if (node.offsetHeight > 150 && node.offsetWidth > 300) {
    return "large";
  } else {
    return "medium";
  }
}

function node_html(node, display_name, text_size, display_number) {
  return `
      <div class="TreeMapNode__ContentBox TreeMapNode__ContentBox--standard">
        <div class="TreeMapNode__ContentTitle ${
          (text_size && `TreeMapNode__ContentTitle${text_size}`) || ""
        }">
          ${display_name}
        </div>
        ${
          (display_number &&
            `
        <div class="TreeMapNode__ContentText ${
          (text_size && `TreeMapNode__ContentText${text_size}`) || ""
        }">
          ${display_number}
        </div>`) ||
          ""
        }
      </div>
      `;
}

function getNodeRenderFunc(htmlForNodeFunc) {
  return function (d3Selection) {
    d3Selection.html(function (node) {
      const node_size = get_node_size(this);
      if (node_size === "tiny") {
        //no contents on tiny nodes
        return;
      }
      return htmlForNodeFunc(node, this.offsetWidth, this.offsetHeight);
    });
  };
}

function std_node_render(foreign_sel) {
  foreign_sel.html(function (node) {
    const node_size = get_node_size(this);
    if (node_size === "tiny") {
      return;
    } //no contents on tiny nodes
    const text_size = node_size === "medium" ? "" : `--${node_size}`;
    const display_name = node.data.name;
    const display_number =
      this.offsetHeight > 50
        ? `${formats.big_int(node.data.amount)} m<sup>2</sup>`
        : "";
    const width = this.offsetWidth;
    return node_html(node, this.offsetWidth, this.offsetHeight);
  });
}
