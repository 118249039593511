import "./Panel.scss";
import { ReactNode } from "react";
import { OverlayTrigger, Button } from "react-bootstrap";

export function Panel({
  children,
  header,
  footer,
}: {
  children?: ReactNode;
  header: ReactNode;
  footer?: ReactNode;
}) {
  return (
    <div className="Panel card my-3">
      <div className="card-header PanelHeader">{header}</div>
      <div className="card-body">{children}</div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
}

export function PanelText({ children }: { children: ReactNode }) {
  return <div className="card-text">{children}</div>;
}
export function PanelTitleText({ children }: { children: ReactNode }) {
  return <div className="card-title">{children}</div>;
}

export function PanelWithPopoverHeader({
  children,
  header,
  popover,
  popoverPlacement,
  footer,
}: {
  children: ReactNode;
  header: ReactNode;
  popover: ReactNode;
  popoverPlacement?: "bottom" | "top" | "left" | "right";
  footer?: ReactNode;
}) {
  const placement = popoverPlacement ?? "bottom";
  const headerContent = (
    <div className="d-flex">
      <div className="me-auto">{header}</div>
      <div className="ms-auto">
        <OverlayTrigger trigger="click" placement={placement} overlay={popover}>
          <Button style={{ height: "1.3rem" }} variant="success">
            <strong
              style={{
                fontSize: "1rem",
                verticalAlign: "top",
                lineHeight: 0.5,
              }}
            >
              ?
            </strong>
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
  const footerContent = footer ? <div>{footer}</div> : null;
  return (
    <Panel header={headerContent} footer={footerContent}>
      {children}
    </Panel>
  );
}

export default Panel;
