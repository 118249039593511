import classNames from "classnames";
import { hierarchy, treemap, treemapSquarify } from "d3-hierarchy";
import { scaleLinear } from "d3-scale";
import { select, selectAll } from "d3-selection";
import "d3-transition";
import "d3-selection-multi";
import _ from "lodash";
import React from "react";
import "./TreeMap.scss";

import tm from "tm";

export class TreeMap extends React.PureComponent {
  getPath() {
    return this.root
      .ancestors()
      .reverse()
      .map(node => node.data);
  }
  zoomOut() {
    this.root = this.root.parent;
    // this.renderNode(this.root);
    this.transition(this.root);
  }
  render() {
    return <div className="TreeMap__Root" ref={div => (this.el = div)} />;
  }
  _update = () => {
    this.root = hierarchy(this.props.data);
    this._imperative_render();
  };
  componentDidMount() {
    this._update();
  }
  componentDidUpdate() {
    this._update();
  }

  _imperative_render() {
    const {
      data,
      colorScale,
      tooltip_render,
      node_render,
      viz_height,
      onZoomIn,
      onClick,
    } = this.props;

    const el = this.el;
    el.innerHTML = `
    <div tabindex="0" id="TreeMap__Main" class="TreeMap__Mainviz">
      <div class="viz-root" style="min-height: ${viz_height}px; position: relative;" >
      </div>
    </div>`;

    const html_root = select(el).select("div");

    // the actual treemap div
    const viz_root = html_root.select(".viz-root");

    const width = viz_root.node().offsetWidth;
    const height = viz_height;

    // sets x and y scale to determine size of visible boxes
    const x = scaleLinear().domain([0, width]).range([0, width]);
    const y = scaleLinear().domain([0, height]).range([0, height]);

    const treemap_instance = treemap()
      .tile(treemapSquarify.ratio(1))
      .round(true)
      .size([width, height]);

    let transitioning;

    // d3 creating the treemap using the data
    // const root = hierarchy(data);
    // this.root = root;

    const { root } = this;
    // set up the node values to be the size, and adjust for cases where the size != sum of children's sizes
    // this avoids having to call d3's sum()
    root.each(d => {
      d.data.value2 = d.data.size;
    });
    root.eachBefore(d => {
      if (d.children && d.data.value2 !== _.sumBy(d.children, "data.value2")) {
        const difference = d.data.value2 - _.sumBy(d.children, "data.value2");
        const total_sum = _.sumBy(d.children, "data.value2");
        _.each(d.children, child => {
          const frac_of_total = child.data.value2 / total_sum;
          child.data.value2 += difference * frac_of_total;
        });
      }
    });
    root.each(d => {
      d.value = d.data.value2;
    });

    treemap_instance(
      root.sort((a, b) => {
        if (a.data.isSmallerItemsGroup) {
          return 9999999;
        }
        if (b.data.isSmallerItemsGroup) {
          return -9999999;
        }
        return b.value - a.value || b.height - a.height;
      })
    );
    // Draw the coloured rectangles
    function rectan(sel) {
      sel.styles(d => ({
        left: `${x(d.x0)}px`,
        top: `${y(d.y0)}px`,
        width: `${x(d.x1) - x(d.x0)}px`,
        height: `${y(d.y1) - y(d.y0)}px`,
        "background-color": colorScale(d),
      }));
    }

    // Draw the invisible text rectangles
    function treemap_node_content_container(sel) {
      sel.styles(d => ({
        left: `${x(d.x0)}px`,
        top: `${y(d.y0)}px`,
        width: `${x(d.x1) - x(d.x0)}px`,
        height: `${y(d.y1) - y(d.y0)}px`,
      }));
    }
    //eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.display = d => {
      const main_group = viz_root.insert("div").datum(d).attr("class", "depth");

      function transition(d) {
        if (transitioning || !d) return;
        transitioning = true;

        // Remove all tooltips when transitioning
        // TODO: will this actually work? this is never set
        // select(this).select(".TM_TooltipContainer").remove();

        const zoomed_group = self.display(d);
        const main_trans = main_group.transition().duration(650);
        const zoomed_trans = zoomed_group.transition().duration(650);

        x.domain([d.x0, d.x1]);
        y.domain([d.y0, d.y1]);

        // Hide overflow while transitioning
        viz_root.style("overflow", "hidden");

        // Draw child nodes on top of parent nodes.
        viz_root.selectAll(".depth").sort((a, b) => a.depth - b.depth);

        // Transition to the new view.
        main_trans.selectAll(".TreeMap__Rectangle").call(rectan);
        zoomed_trans.selectAll(".TreeMap__Rectangle").call(rectan);

        // Remove text when transitioning, then display again
        main_trans
          .selectAll(".TreeMapNode__ContentBox")
          .style("display", "none");
        main_trans
          .selectAll(".TreeMapNode__ContentBoxContainer")
          .call(treemap_node_content_container);
        zoomed_trans
          .selectAll(".TreeMapNode__ContentBox")
          .style("display", "block");
        zoomed_trans
          .selectAll(".TreeMapNode__ContentBoxContainer")
          .call(treemap_node_content_container);

        zoomed_trans
          .selectAll(".TreeMapNode__ContentBoxContainer")
          .call(treemap_node_content_container); // TODO: why is this here?

        // Remove the old node when the transition is finished.
        main_trans.on("end.remove", function () {
          this.remove();
          transitioning = false;
          viz_root.style("overflow", "visible");
          zoomed_group
            .selectAll(".TreeMapNode__ContentBoxContainer")
            .call(node_render);
        });
      }
      self.transition = transition;

      if (!d.children) {
        return;
      }

      main_group.html("");
      const main = main_group
        .selectAll(".TreeMap__Division")
        .data(d.children)
        .enter()
        .append("div");

      main
        .filter(d => d.children)
        .classed("TreeMap__Division", true)
        .on("click", d => {
          this.root = d;
          onZoomIn(d.data);
          transition(d);
        });
      // .on("keydown", d => {
      //   if (event.keyCode != 13) {
      //     return;
      //   }
      //   transition(d);
      // });

      main
        .selectAll(".TreeMap__Rectangle--is-child")
        .data(d => d.children || [d])
        .enter()
        .append("div")
        .attr("class", "TreeMap__Rectangle TreeMap__Rectangle--is-child")
        .call(rectan);

      main
        .append("div")
        .attr("class", "TreeMap__Rectangle TreeMap__Rectangle--is-parent")
        .call(rectan);

      main
        .append("div")
        .attr("class", d =>
          classNames(
            "TreeMapNode__ContentBoxContainer",
            !_.isEmpty(d.children) &&
              "TreeMapNode__ContentBoxContainer--has-children"
          )
        )
        .attr("tabindex", "0")
        .on("click", d => {
          if (onClick) {
            onClick(d.data);
          }
        })
        .on("mouseenter focus", function (d) {
          select(this).selectAll(".TM_TooltipContainer").remove();
          setTimeout(() => {
            selectAll(".TM_TooltipContainer").remove();
            var tool = select(this)
              .append("div")
              .attr("class", "TM_TooltipContainer")
              .style("opacity", 0);
            tool.transition().style("opacity", 1);
            tool.call(tooltip_render);
          }, 300);
        })
        .on("mouseleave", function (d) {
          select(this).selectAll(".TM_TooltipContainer").remove();
        })
        .call(treemap_node_content_container);

      return main;
    };
    this.renderNode(root);
  }
  renderNode(d) {
    const main = this.display(d);
    //node_render is special, we call it once on first render (here)
    //and after transitions
    if (main) {
      main
        .selectAll(".TreeMapNode__ContentBoxContainer")
        .call(this.props.node_render);
    }
  }
}
