import { Bar, ResponsiveBar } from "@nivo/bar";

import {
  useContext,
  useState,
  createContext,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { useMatch, useNavigate, useParams } from "react-router";
import Select from "react-select";

import Panel from "components/Panel";

import GreeningRecord from "subjects/GreeningRecord";
import ThreeYearRecord from "subjects/ThreeYearRecord";
import { hrefWithLang, useToggle } from "utils";

import {
  CommonTreemap,
  SingleDepartmentHierarchyGenerator,
  TreemapMode,
} from "./hierarchies";
import {
  commonBarProps,
  getBarChartHeight,
  GigaJoulesBarTooltip,
  GigaJoulesPerSqmBarTooltip,
  KgCo2PerSqmTooltip,
  TonsCo2BarTooltip,
} from "./utils";

function getDepartmentChoices(): string[] {
  return _.sortBy(
    _.uniq([
      ...ThreeYearRecord.all().map(r => r.department),
      ...GreeningRecord.all().map(r => r.department),
    ]),
    d => d.toLowerCase()
  );
}

interface DepartmentDashboardState {
  department?: string;
}
const BuildingDashboardContext = createContext<DepartmentDashboardState>();

export function DepartmentDashboardProvider({
  children,
}: {
  children: ReactNode;
}) {
  // const x = useParams();
  // const { department } = x;
  const department = useMatch(hrefWithLang("/dashboard/department/:department"))
    ?.params?.department;

  const provided = { department };

  return (
    <BuildingDashboardContext.Provider value={provided}>
      {children}
    </BuildingDashboardContext.Provider>
  );
}

export function useDepartmentDashboardState() {
  const { department } = useContext(BuildingDashboardContext);

  const navigate = useNavigate();
  const setDepartment = useCallback(
    (department?: string) =>
      navigate(hrefWithLang(`/dashboard/department/${department || ""}`)),
    [navigate]
  );
  return {
    department,
    setDepartment,
  };
}

function DepartmentSelection() {
  const { department, setDepartment } = useDepartmentDashboardState();
  const allDepartments = useMemo(
    () => _.map(getDepartmentChoices(), d => ({ value: d, label: d })),
    []
  );

  return (
    <div>
      <Select
        value={_.find(allDepartments, { value: department })}
        onChange={option => setDepartment(option?.value)}
        options={allDepartments}
        placeholder={
          department
            ? tdt("Select another department")
            : tdt("Select a department")
        }
        isClearable
      />
    </div>
  );
}

export function DepartmentTab() {
  return (
    <DepartmentDashboardProvider>
      <div>
        <DepartmentSelection />
        <DepartmentTabContent />
      </div>
    </DepartmentDashboardProvider>
  );
}

function DepartmentTabContent() {
  const { department } = useDepartmentDashboardState();
  if (!department) {
    return (
      <div>
        <Panel header={tdt("Select a department")}>
          <p>{tdt("Select a department to get started")}</p>
        </Panel>
      </div>
    );
  }
  return (
    <div>
      <BuildingsGhg />
      <BuildingsEnergy />
      <Treemap />
      <HistoricalGhg />
    </div>
  );
}

function BuildingsGhg() {
  const { department } = useDepartmentDashboardState();

  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const barData = useMemo(() => {
    return _.chain(GreeningRecord.all())
      .filter({ department })
      .filter("totalGhg")
      .filter(shouldUseSqm ? r => r.area > 0 : r => true)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? (1000 * r.totalGhg) / r.area : r.totalGhg,
        id: r.building,
        label: r.building,
      }))
      .sortBy("value")
      .reverse()
      .value();
  }, [department, shouldUseSqm]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  return (
    <Panel header={tdt("GHG By building (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>

      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={
            shouldUseSqm
              ? value => `${value} kgCO₂ / m²`
              : value => `${value} tCO₂`
          }
          axisBottom={{
            legend: shouldUseSqm
              ? tdt("GHG Emissions (kgCO₂) per m²")
              : tdt("GHG Emissions (tCO₂)"),
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
          tooltip={shouldUseSqm ? KgCo2PerSqmTooltip : TonsCo2BarTooltip}
        />
      </div>
    </Panel>
  );
}

function BuildingsEnergy() {
  const { department } = useDepartmentDashboardState();

  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const barData = useMemo(() => {
    return _.chain(GreeningRecord.all())
      .filter({ department })
      .filter("totalGhg")
      .filter(shouldUseSqm ? r => r.area > 0 : r => true)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? r.totalEnergy / r.area : r.totalEnergy,
        id: r.building,
        label: r.building,
      }))
      .sortBy("value")
      .reverse()
      .value();
  }, [department, shouldUseSqm]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  return (
    <Panel header={tdt("Energy consumption by building (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>

      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={
            shouldUseSqm
              ? value => `${value} kgCO₂ / m²`
              : value => `${value} tCO₂`
          }
          axisBottom={{
            legend: shouldUseSqm ? tdt("GJ per m²") : tdt("GJ"),
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
          tooltip={
            shouldUseSqm ? GigaJoulesPerSqmBarTooltip : GigaJoulesBarTooltip
          }
        />
      </div>
    </Panel>
  );
}

function HistoricalGhg() {
  return <Panel header={tdt("GHG Emissions Trend")}>TODO</Panel>;
}

function Treemap() {
  const { department } = useDepartmentDashboardState();
  const [mode, setMode] = useState<TreemapMode>("ghg");
  const data = useMemo(() => {
    return new SingleDepartmentHierarchyGenerator(
      mode,
      department as string
    ).getRoot();
  }, [mode, department]);

  const onChange = useCallback(
    e => {
      setMode(e.target.value);
    },
    [setMode]
  );

  return (
    <Panel header="Treemap: Departments (2019)">
      <select className="form-control mb-3" value={mode} onChange={onChange}>
        <option value="ghg">{tdt("GHG emissions")}</option>
        <option value="energy">{tdt("Energy consumed")}</option>
      </select>
      <CommonTreemap data={data} mode={mode} />
    </Panel>
  );
}
