import { NavDropdown, Nav, Navbar as BSNavbar } from "react-bootstrap";

import tm from "tm";
import { hrefWithLang } from "utils";

export default function Navbar() {
  return (
    <BSNavbar bg="light" expand="lg" variant="primary-bg-color">
      <div className="container-fluid">
        <BSNavbar.Brand>
          <h1 className="my-0 fs-3">{tm("title")}</h1>
        </BSNavbar.Brand>
        <BSNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BSNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href={window.location.href.replace(
                `/${window.lang}/`,
                `/${window.other_lang}/`
              )}
            >
              {window.lang === "en" ? "Français" : "English"}
            </Nav.Link>
          </Nav>
        </BSNavbar.Collapse>
      </div>
    </BSNavbar>
  );
}
