import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine, ResponsiveLineCanvas } from "@nivo/line";
import { useCallback, useMemo, useState } from "react";

// import { LangLink } from "components/LangLink";

import { Format } from "components/Format";
import Panel from "components/Panel";

import GreeningRecord from "subjects/GreeningRecord";
import ThreeYearRecord from "subjects/ThreeYearRecord";

import { useToggle } from "utils";

import Page from "../components/Page";

import {
  CommonTreemap,
  RegionalHierarchyGenerator,
  DepartmentBuildingHierarchyGenerator,
  TreemapMode,
  GeographicalRegionHierarchyGenerator,
} from "./hierarchies";
import {
  commonBarProps,
  getBarChartHeight,
  KgCo2PerSqmTooltip,
  TonsCo2BarTooltip,
} from "./utils";

function getThreeYearByOrgData() {
  return _.chain(ThreeYearRecord.all())
    .groupBy("department")
    .map((records, department) => {
      return {
        id: department,
        label: department,
        year1: _.sumBy(records, r => r.ghg[0] || 0),
        year2: _.sumBy(records, r => r.ghg[1] || 0),
        year3: _.sumBy(records, r => r.ghg[2] || 0),
      };
    })
    .value();
}

export function AggregateTab() {
  return (
    <div>
      <DepartmentGhg />
      <ProvinceGhg />
      <RegionalTreemap />
      <DeptTreemap />
      <HistoricalGhg />
      <GeographicalRegionTreemap />
    </div>
  );
}

function HistoricalGhg() {
  const lineData = useMemo(() => {
    const allData = ThreeYearRecord.all();

    return [
      {
        id: "all",
        label: "All",
        data: [
          { x: "2018", y: _.sumBy(allData, r => r.ghg[0] || 0) },
          { x: "2019", y: _.sumBy(allData, r => r.ghg[1] || 0) },
          { x: "2020", y: _.sumBy(allData, r => r.ghg[2] || 0) },
        ],
      },
    ];
  }, []);

  const tooltip = useCallback(({ point }) => {
    return (
      <div className="HoverLabel">
        <div>{point.data.x}</div>
        <div>
          {" "}
          <Format content={point.data.y} type="big_int" /> tCO<sub>2</sub>
        </div>
      </div>
    );
  }, []);

  return (
    <div>
      <Panel header={tdt("GHG Trend")}>
        <div style={{ height: "600px" }}>
          <ResponsiveLineCanvas
            data={lineData}
            margin={{ top: 50, right: 110, bottom: 50, left: 100 }}
            tooltip={tooltip}
            isInteractive
            axisLeft={{
              format: value => `${formats.big_int(value, { raw: true })}`,
            }}
          />
        </div>
        {/* <div style={{ height: "900px" }}>
          <ResponsiveBar
            data={barData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            isInteractive
            keys={["2018", "2019", "2020"]}
            indexBy="id"
            groupMode="grouped"
            layout="horizontal"
            //   legends={[
            //     {
            //       anchor: "bottom-right",
            //       direction: "column",
            //       justify: false,
            //       translateX: 100,
            //       translateY: 0,
            //       itemsSpacing: 0,
            //       itemDirection: "left-to-right",
            //       itemWidth: 80,
            //       itemHeight: 20,
            //       itemOpacity: 0.75,
            //       symbolSize: 12,
            //       symbolShape: "circle",
            //     },
            //   ]}
          />
        </div> */}
      </Panel>
    </div>
  );
}

function DepartmentGhg() {
  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const data = useMemo(
    () =>
      _.chain(GreeningRecord.all())
        .filter(r => r.totalGhg >= 0)
        .filter(r => r.area >= 0)
        .groupBy("department")
        .map((records, department) => {
          return {
            id: department,
            label: department,
            totalGhg: _.sumBy(records, r => r.totalGhg || 0),
            totalArea: _.sumBy(records, r => r.area || 0),
          };
        })
        .value(),
    []
  );

  const barData = useMemo(() => {
    return _.chain(data)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? (1000 * r.totalGhg) / r.totalArea : r.totalGhg,
      }))
      .sortBy("value")
      .value();
  }, [shouldUseSqm, data]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  const unit = shouldUseSqm ? "kgCO₂ / m²" : "tCO₂";

  return (
    <Panel header={tdt("Emissions by Department (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>
      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={value => `${value} ${unit}`}
          tooltip={shouldUseSqm ? KgCo2PerSqmTooltip : TonsCo2BarTooltip}
          axisBottom={{
            legend: unit,
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
        />
      </div>
    </Panel>
  );
}

function ProvinceGhg() {
  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const data = useMemo(
    () =>
      _.chain(GreeningRecord.all())
        .filter(r => r.totalGhg >= 0)
        .filter(r => r.area >= 0)
        .groupBy("province")
        .map((records, province) => {
          return {
            id: province,
            label: province,
            totalGhg: _.sumBy(records, r => r.totalGhg || 0),
            totalArea: _.sumBy(records, r => r.area || 0),
          };
        })
        .value(),
    []
  );

  const barData = useMemo(() => {
    return _.chain(data)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? (1000 * r.totalGhg) / r.totalArea : r.totalGhg,
      }))
      .sortBy("value")
      .value();
  }, [shouldUseSqm, data]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  const unit = shouldUseSqm ? "kgCO₂ / m²" : "tCO₂";

  return (
    <Panel header={tdt("Emissions by Province (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>
      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={value => `${value} ${unit}`}
          tooltip={shouldUseSqm ? KgCo2PerSqmTooltip : TonsCo2BarTooltip}
          axisBottom={{
            legend: unit,
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
        />
      </div>
    </Panel>
  );
}

function RegionalTreemap() {
  const [mode, setMode] = useState<TreemapMode>("ghg");
  const data = useMemo(() => {
    return new RegionalHierarchyGenerator(mode).getRoot();
  }, [mode]);

  const onChange = useCallback(
    e => {
      setMode(e.target.value);
    },
    [setMode]
  );

  return (
    <Panel header="Treemap: Provinces (2019)">
      <select className="form-control mb-3" value={mode} onChange={onChange}>
        <option value="ghg">{tdt("GHG emissions")}</option>
        <option value="energy">{tdt("Energy consumed")}</option>
      </select>
      <CommonTreemap data={data} mode={mode} />
    </Panel>
  );
}

function DeptTreemap() {
  const [mode, setMode] = useState<TreemapMode>("ghg");
  const data = useMemo(() => {
    return new DepartmentBuildingHierarchyGenerator(mode).getRoot();
  }, [mode]);

  const onChange = useCallback(
    e => {
      setMode(e.target.value);
    },
    [setMode]
  );

  return (
    <Panel header="Treemap: Departments (2019)">
      <select className="form-control mb-3" value={mode} onChange={onChange}>
        <option value="ghg">{tdt("GHG emissions")}</option>
        <option value="energy">{tdt("Energy consumed")}</option>
      </select>
      <CommonTreemap data={data} mode={mode} />
    </Panel>
  );
}

function GeographicalRegionTreemap() {
  const [mode, setMode] = useState<TreemapMode>("ghg");
  const data = useMemo(() => {
    return new GeographicalRegionHierarchyGenerator(mode).getRoot();
  }, [mode]);

  const onChange = useCallback(
    e => {
      setMode(e.target.value);
    },
    [setMode]
  );

  return (
    <Panel header="Treemap: Regions (2019)">
      <select className="form-control mb-3" value={mode} onChange={onChange}>
        <option value="ghg">{tdt("GHG emissions")}</option>
        <option value="energy">{tdt("Energy consumed")}</option>
      </select>
      <CommonTreemap data={data} mode={mode} />
    </Panel>
  );
}
