import { Format } from "components/Format";
import theme from "variables.module.scss";

const BAR_HEIGHT = 30;
export function getBarChartHeight(numBars, barsPerGroup = 1, minHeight = 350) {
  return `${Math.max(numBars * BAR_HEIGHT * barsPerGroup, minHeight)}px`;
}

export const commonBarTheme = {
  fontSize: 14,
  axis: {
    legend: {
      text: {
        fontSize: 14,
      },
    },
  },
};

export const commonBarProps = {
  layout: "horizontal",
  margin: { top: 50, right: 10, bottom: 75, left: 250 },
  theme: commonBarTheme,
  colors: theme.primaryColor,
};

export function TonsCo2BarTooltip({ data }) {
  return (
    <div className="HoverLabel">
      <div>{data.label}</div>
      <div>
        {" "}
        <Format content={data.value} type="big_int" /> tCO<sub>2</sub>
      </div>
    </div>
  );
}

export function GigaJoulesBarTooltip({ data }) {
  return (
    <div className="HoverLabel">
      <div>{data.label}</div>
      <div>
        {" "}
        <Format content={data.value} type="big_int" /> GJ
      </div>
    </div>
  );
}

export function GigaJoulesPerSqmBarTooltip({ data }) {
  return (
    <div className="HoverLabel">
      <div>{data.label}</div>
      <div>
        {" "}
        <Format content={data.value} type="decimal2" /> GJ per m<sup>2</sup>
      </div>
    </div>
  );
}

export function KgCo2PerSqmTooltip({ data }) {
  return (
    <div className="HoverLabel">
      <div>{data.label}</div>
      <div>
        {" "}
        <Format content={data.value} type="big_int" /> kg CO<sub>2</sub> per m
        <sup>2</sup>
      </div>
    </div>
  );
}
