import { ThreeYearRecord } from "./ThreeYearRecord";

export interface GreeningRecordDatum {
  id: string;
  department: string;
  building: string;
  address: string;
  city: string;
  province: string;
  area?: number;
  totalEnergy?: number;
  totalGhg?: number;
  geographicArea?: string;
}

class GreeningRecord {
  private static _all: { [id: string]: GreeningRecord } = {};
  constructor(obj: GreeningRecordDatum) {
    Object.assign(this, obj);
    GreeningRecord._all[obj.id] = this;
  }
  get threeYearRecord(): ThreeYearRecord {
    return ThreeYearRecord.getById(this.id);
  }
  get geoAreaLabel() {
    return this.geographicArea || tdt("Unspecfied");
  }

  static all() {
    return _.values(this._all);
  }
  static getById(id: string) {
    return this._all[id];
  }
}
interface GreeningRecord extends GreeningRecordDatum {}
export { GreeningRecord };
export default GreeningRecord;
window.GreeningRecord = GreeningRecord;
