import { useLayoutEffect, useState } from "react";

import theme from "variables.module.scss";

export async function wait(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export function ellipsize(str: string, limit = 100, content = "...") {
  if (str.length > limit - content.length) {
    return `${str.substr(0, limit)}${content}`;
  }
  return str;
}

export function hrefWithLang(href: string) {
  //assumes that the URL starts with /
  return `/${window.lang}${href}`;
}

export function useToggle(initial: boolean) {
  const [isToggled, setToggled] = useState(initial);
  const toggle = () => setToggled(!isToggled);
  return [isToggled, toggle] as const;
}

export const primaryColor = theme.primaryColor;
export const getPrimaryColor = () => primaryColor;

export function useWindowWidth(delay = 700) {
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const debouncedHandleResize = _.debounce(handleResize, delay);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [delay]);

  return width;
}
