import { csvParse } from "d3-dsv";

import GreeningRecord, { GreeningRecordDatum } from "./GreeningRecord";
import ThreeYearRecord from "./ThreeYearRecord";

//eslint-disable-next-line
import threeYearDAta from 'data/threeYearData.json';

//eslint-disable-next-line
import greeningCsv from "!raw-loader!data/masterGreeningData.csv";


type GreeningRecordCsvRow = GreeningRecordDatum & {
  area: string;
  totalEnergy: string;
  totalGhg: string;
};
const numOrUndef = val => (parseFloat(val) ? parseFloat(val) : undefined);

csvParse(greeningCsv, (row: GreeningRecordCsvRow) => {
  new GreeningRecord({
    ...row,
    area: numOrUndef(row.area),
    totalEnergy: numOrUndef(row.totalEnergy),
    totalGhg: numOrUndef(row.totalGhg),
    geographicArea: row.geographicArea || undefined,
  });
});

threeYearDAta.forEach(obj => {
  new ThreeYearRecord(obj);
});
