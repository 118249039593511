import { tm } from "tm";

import { group_smallest, prep_nodes } from "./dataUtil";

export function getTextSizeClass(width: number, height: number) {
  if (height <= 30 || width <= 50) {
    return "tiny";
  } else if (height < 100 || width < 150) {
    return "small";
  } else if (height > 150 && width > 300) {
    return "large";
  } else {
    return "medium";
  }
}

export abstract class HierarchyGenerator<T = void> {
  public getRoot(): HierarchyNode<T> {
    const root = {
      id: "root",
      name: "",
      type: "root",
      children: this.getFirstLevel(),
    };
    prep_nodes(root);
    root.children = group_smallest(
      root.children,
      children => ({
        name: tm("smaller_items"),
        isSmallerItemsGroup: true,
        children,
        type: "smaller_group",
      }),
      true,
      0.02
    );

    return root;
  }
  protected abstract getFirstLevel(): HierarchyNode<T>[];
}

export interface HierarchyNode<T = void> {
  children?: HierarchyNode<T>[];
  name: string;
  id: string;
  amount?: number; //this is just leaves
  data?: T;
}
