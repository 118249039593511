import tm from "tm";

export function has_non_zero_or_non_zero_children(node) {
  if (_.isEmpty(node.children)) {
    return node.amount > 0;
  } else {
    return _.some(node.children, has_non_zero_or_non_zero_children);
  }
}

export function recurse_adjust_size(node, parent_ratio) {
  const new_size = node.size * parent_ratio;
  _.set(node, "size", new_size);
  _.each(node.children, child => {
    recurse_adjust_size(child, new_size / node.amount);
  });
}

export function group_smallest(
  node_list,
  node_creator,
  should_recurse = true,
  perc_cutoff = 0.02,
  should_adjust_size = true
) {
  if (_.isEmpty(node_list)) {
    return node_list;
  }
  if (should_recurse) {
    //apply recursion first
    _.each(node_list, child => {
      child.children = group_smallest(
        child.children,
        node_creator,
        should_recurse,
        perc_cutoff
      );
    });
  }

  const total = _.sumBy(node_list, "size");
  const cutoff = total * perc_cutoff; //TODO: modify cutoff based on screenWidth, mobile should have more nesting for visibility...
  let tiny_nodes = _.filter(node_list, ({ size }) => size < cutoff);
  const non_tiny_nodes = _.filter(node_list, ({ size }) => size >= cutoff);

  // we want to avoid making another level to the hierarchy if unnecessary
  if (non_tiny_nodes.length && tiny_nodes.length > 3) {
    const new_node = node_creator(tiny_nodes);

    new_node.amount = _.sumBy(tiny_nodes, "amount");
    new_node.ftes = _.sumBy(tiny_nodes, "ftes");
    new_node.size = _.sumBy(tiny_nodes, "size");
    new_node.is_negative = new_node.amount < 0;

    const shouldGroupSmaller =
      new_node.size < cutoff && non_tiny_nodes.length > 0;

    if (shouldGroupSmaller && should_adjust_size) {
      const old_size = new_node.size;
      _.set(new_node, "size", cutoff);
      _.each(new_node.children, child => {
        recurse_adjust_size(child, cutoff / old_size);
      });
    }

    if (should_recurse) {
      //the newly split up children might be able to get grouped again!
      new_node.children = group_smallest(
        tiny_nodes,
        node_creator,
        should_recurse,
        perc_cutoff
      );
    }

    const old_node_list = _.without(node_list, ...tiny_nodes);

    return old_node_list.concat(new_node);
  } else if (tiny_nodes.length > 0 && should_adjust_size) {
    // we want to avoid cases where there are nodes that are too tiny to see
    // e.g. DRF > treasury board > PSIC
    const old_node_list = _.without(node_list, ...tiny_nodes);
    tiny_nodes = _.each(tiny_nodes, function (item) {
      const old_size = item.size;
      _.set(item, "size", cutoff);
      _.each(item.children, child => {
        recurse_adjust_size(child, cutoff / old_size);
      });
    });

    return old_node_list.concat(tiny_nodes);
  } else {
    return node_list;
  }
}

export function prep_nodes(node) {
  const { children } = node;
  if (!_.isEmpty(children)) {
    _.each(children, child => {
      prep_nodes(child);
    });
    if (!node.amount) {
      node.amount = _.sumBy(children, "amount");
    }
    node.size = _.sumBy(children, "size");
    _.each(children, n => {
      _.set(n, "parent_amount", node.amount);
      _.set(n, "parent_name", node.name);
    });
  } else {
    //leaf node, already has amount but no size
    node.size = node.amount;
  }
}
