import { useCallback, useMemo } from "react";
import { Route, Routes, useMatch } from "react-router-dom";

import { LangLink } from "components/LangLink";

import { hrefWithLang } from "utils";

import Page from "../components/Page";

import { AggregateTab } from "./AggregateTab";
import { DepartmentTab } from "./DepartmentTab";
import { ProvinceTab } from "./ProvinceTab";
import { RegionTab } from "./RegionTab";

export function Dashboard() {
  const activeTab = useMatch(hrefWithLang("/dashboard/:tabKey/*"))?.params
    ?.tabKey;

  const activeProps = {
    "aria-current": "page",
    className: "nav-link active",
  };
  const nonActiveProps = {
    className: "nav-link",
  };

  return (
    <Page>
      <div className="container">
        <h1>{tdt("Dashboard")}</h1>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <LangLink
              {...(activeTab === undefined ? activeProps : nonActiveProps)}
              to="/dashboard/"
            >
              {tdt("Summary")}
            </LangLink>
          </li>
          <li className="nav-item">
            <LangLink
              {...(activeTab === "department" ? activeProps : nonActiveProps)}
              to="/dashboard/department/"
            >
              {tdt("Department View")}
            </LangLink>
          </li>
          <li className="nav-item">
            <LangLink
              {...(activeTab === "province" ? activeProps : nonActiveProps)}
              to="/dashboard/province/"
            >
              {tdt("Provincial View")}
            </LangLink>
          </li>
          <li className="nav-item">
            <LangLink
              {...(activeTab === "region" ? activeProps : nonActiveProps)}
              to="/dashboard/region/"
            >
              {tdt("Region View")}
            </LangLink>
          </li>
        </ul>
        <div className="mt-3 mb-5">
          <Routes>
            <Route path="" element={<AggregateTab />} />
            <Route path="department/*" element={<DepartmentTab />} />
            <Route path="province/*" element={<ProvinceTab />} />
            <Route path="region/*" element={<RegionTab />} />
          </Routes>
        </div>
      </div>
    </Page>
  );
}
