import { Bar, ResponsiveBar } from "@nivo/bar";

import {
  useContext,
  useState,
  createContext,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { useMatch, useNavigate, useParams } from "react-router";
import Select from "react-select";

import Panel from "components/Panel";

import GreeningRecord from "subjects/GreeningRecord";
import { hrefWithLang, useToggle } from "utils";

import {
  CommonTreemap,
  ProvinceTreemapDrillDownMode,
  SingleProvinceHierarchyGenerator,
} from "./hierarchies";
import {
  commonBarProps,
  getBarChartHeight,
  GigaJoulesBarTooltip,
  GigaJoulesPerSqmBarTooltip,
  KgCo2PerSqmTooltip,
  TonsCo2BarTooltip,
} from "./utils";

function getProvinceChoices(): string[] {
  return _.chain(GreeningRecord.all())
    .map(r => r.province)
    .uniqBy(p => p.toLowerCase())
    .sortBy(p => p.toLowerCase())
    .value();
}

interface ProvinceDashboardState {
  province?: string;
}
const BuildingDashboardContext = createContext<ProvinceDashboardState>();

export function ProvinceDashboardProvider({
  children,
}: {
  children: ReactNode;
}) {
  // const x = useParams();
  // const { province } = x;
  const province = useMatch(hrefWithLang("/dashboard/province/:province"))
    ?.params?.province;

  const provided = { province };

  return (
    <BuildingDashboardContext.Provider value={provided}>
      {children}
    </BuildingDashboardContext.Provider>
  );
}

export function useProvinceDashboardState() {
  const { province } = useContext(BuildingDashboardContext);

  const navigate = useNavigate();
  const setProvince = useCallback(
    (province?: string) =>
      navigate(hrefWithLang(`/dashboard/province/${province || ""}`)),
    [navigate]
  );
  return {
    province,
    setProvince,
  };
}

function ProvinceSelection() {
  const { province, setProvince } = useProvinceDashboardState();
  const allProvinces = useMemo(
    () => _.map(getProvinceChoices(), d => ({ value: d, label: d })),
    []
  );

  return (
    <div>
      <Select
        value={_.find(allProvinces, { value: province })}
        onChange={option => setProvince(option?.value)}
        options={allProvinces}
        placeholder={
          province ? tdt("Select another province") : tdt("Select a province")
        }
        isClearable
      />
    </div>
  );
}

export function ProvinceTab() {
  return (
    <ProvinceDashboardProvider>
      <div>
        <ProvinceSelection />
        <ProvinceTabContent />
      </div>
    </ProvinceDashboardProvider>
  );
}

function ProvinceTabContent() {
  const { province } = useProvinceDashboardState();
  if (!province) {
    return (
      <div>
        <Panel header={tdt("Select a province")}>
          <p>{tdt("Select a province to get started")}</p>
        </Panel>
      </div>
    );
  }
  return (
    <div>
      <BuildingsGhg />
      <BuildingsEnergy />
      <Treemap />
      <HistoricalGhg />
    </div>
  );
}

function BuildingsGhg() {
  const { province } = useProvinceDashboardState();

  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const barData = useMemo(() => {
    return _.chain(GreeningRecord.all())
      .filter({ province })
      .filter("totalGhg")
      .filter(shouldUseSqm ? r => r.area > 0 : r => true)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? (1000 * r.totalGhg) / r.area : r.totalGhg,
        id: r.building,
        label: r.building,
      }))
      .sortBy("value")
      .reverse()
      .value();
  }, [province, shouldUseSqm]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  return (
    <Panel header={tdt("GHG By building (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>

      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={
            shouldUseSqm
              ? value => `${value} kgCO₂ / m²`
              : value => `${value} tCO₂`
          }
          axisBottom={{
            legend: shouldUseSqm
              ? tdt("GHG Emissions (kgCO₂) per m²")
              : tdt("GHG Emissions (tCO₂)"),
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
          tooltip={shouldUseSqm ? KgCo2PerSqmTooltip : TonsCo2BarTooltip}
        />
      </div>
    </Panel>
  );
}

function BuildingsEnergy() {
  const { province } = useProvinceDashboardState();

  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const barData = useMemo(() => {
    return _.chain(GreeningRecord.all())
      .filter({ province })
      .filter("totalGhg")
      .filter(shouldUseSqm ? r => r.area > 0 : r => true)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? r.totalEnergy / r.area : r.totalEnergy,
        id: r.building,
        label: r.building,
      }))
      .sortBy("value")
      .reverse()
      .value();
  }, [province, shouldUseSqm]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  return (
    <Panel header={tdt("Energy consumption by building (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>

      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={
            shouldUseSqm
              ? value => `${value} kgCO₂ / m²`
              : value => `${value} tCO₂`
          }
          axisBottom={{
            legend: shouldUseSqm ? tdt("GJ per m²") : tdt("GJ"),
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
          tooltip={
            shouldUseSqm ? GigaJoulesPerSqmBarTooltip : GigaJoulesBarTooltip
          }
        />
      </div>
    </Panel>
  );
}

function HistoricalGhg() {
  return <Panel header={tdt("GHG Emissions Trend")}>TODO</Panel>;
}

function Treemap() {
  const { province } = useProvinceDashboardState();
  const [mode, setMode] = useState<TreemapMode>("ghg");
  const [drillDownMode, setDrillDownMode] =
    useState<ProvinceTreemapDrillDownMode>("department");
  const data = useMemo(() => {
    return new SingleProvinceHierarchyGenerator(
      mode,
      province as string,
      drillDownMode
    ).getRoot();
  }, [mode, province, drillDownMode]);

  const onChange = useCallback(
    e => {
      setMode(e.target.value);
    },
    [setMode]
  );
  const onChangeDrillDown = useCallback(
    e => {
      setDrillDownMode(e.target.value);
    },
    [setDrillDownMode]
  );

  return (
    <Panel header="Treemap: Departments (2019)">
      <div className="row">
        <div className="col-md-6">
          <label>
            {tdt("Selected Metric")}
            <select
              className="form-control mb-3"
              value={mode}
              onChange={onChange}
            >
              <option value="ghg">{tdt("GHG emissions")}</option>
              <option value="energy">{tdt("Energy consumed")}</option>
            </select>
          </label>
        </div>
        <div className="col-md-6">
          <label>
            {tdt("Drilldown method")}
            <select
              className="form-control mb-3"
              value={drillDownMode}
              onChange={onChangeDrillDown}
            >
              <option value="department">{tdt("Department")}</option>
              <option value="city">{tdt("City")}</option>
            </select>
          </label>
        </div>
      </div>
      <CommonTreemap data={data} mode={mode} />
    </Panel>
  );
}
