import "bootstrap/dist/css/bootstrap.min.css";
import "base.scss";
import { Route, Routes, Navigate } from "react-router-dom";
import "./subjects/populateSubjects";

import { Dashboard } from "./Dashboard/Dashboard";

function App() {
  const lang = window.lang;
  return (
    <div className="App">
      <Routes>
        <Route path={`${lang}/dashboard/*`} element={<Dashboard />} />
        <Route
          path={`${lang}/`}
          element={<Navigate replace to={`/${lang}/dashboard/`} />}
        />
        <Route path="/" element={<Navigate replace to="/en/dashboard/" />} />
      </Routes>
    </div>
  );
}

export default App;
