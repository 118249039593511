import { formats } from "components/Format";
import {
  HierarchyGenerator,
  getTextSizeClass,
  HierarchyNode as BaseHierarchyNode,
} from "components/TreeMap/util";
import WrappedTreeMap from "components/TreeMap/WrappedTreemap";

import { GreeningRecord } from "subjects/GreeningRecord";
import tm from "tm";
import { getPrimaryColor } from "utils";

export type TreemapMode = "ghg" | "energy";

function getGhg(record: GreeningRecord) {
  return record.totalGhg || 0;
}
function getEnergy(record: GreeningRecord) {
  return record.totalEnergy || 0;
}

function getGroupData(records: GreeningRecord[]): HierarchyData {
  return {
    totalEnergy: _.sumBy(records, r => r.totalEnergy || 0),
    totalGhg: _.sumBy(records, r => r.totalGhg || 0),
    area: _.sumBy(records, r => r.area || 0),
  };
}

function getRecordData(record: GreeningRecord): HierarchyData {
  return {
    totalGhg: record.totalGhg || 0,
    totalEnergy: record.totalEnergy || 0,
    area: record.area,
  };
}

interface HierarchyData {
  totalGhg: number;
  totalEnergy: number;
  area?: number;
}

type HierarchyNode = BaseHierarchyNode<HierarchyData>;

abstract class RecordBasedHierarchyGenerator extends HierarchyGenerator<HierarchyData> {
  mode: TreemapMode;
  constructor(mode: TreemapMode) {
    super();
    this.mode = mode;
  }
  getValueForRecord(record: GreeningRecord) {
    if (this.mode === "ghg") {
      return getGhg(record);
    } else {
      return getEnergy(record);
    }
  }
}

export class DepartmentBuildingHierarchyGenerator extends RecordBasedHierarchyGenerator {
  protected getFirstLevel(): HierarchyNode[] {
    const d = _.chain(GreeningRecord.all())
      .filter(r => this.getValueForRecord(r))
      .groupBy("department")
      .map((buildingRecords, department) => ({
        id: department,
        name: department,
        data: getGroupData(buildingRecords),
        children: _.map(buildingRecords, (record: GreeningRecord) => ({
          id: record.id,
          amount: this.getValueForRecord(record),
          name: record.building,
          data: getRecordData(record),
        })),
      }))
      .value();
    return d;
  }
}

export class RegionalHierarchyGenerator extends RecordBasedHierarchyGenerator {
  protected getFirstLevel(): HierarchyNode[] {
    return _.chain(GreeningRecord.all())
      .filter(r => this.getValueForRecord(r))
      .groupBy("province")
      .map((provinceRecords, province) => ({
        id: province,
        name: province,
        data: getGroupData(provinceRecords),
        children: _.chain(provinceRecords)
          .groupBy(r => r.city || "Unknown")
          .map((cityRecords, city) => ({
            id: city,
            name: city,
            data: getGroupData(cityRecords),
            children: _.map(cityRecords, (record: GreeningRecord) => ({
              id: record.id,
              name: `${record.building} - ${record.department}`,
              amount: this.getValueForRecord(record),
              data: getRecordData(record),
            })),
          }))
          .value(),
      }))
      .value();
  }
}

export class GeographicalRegionHierarchyGenerator extends RecordBasedHierarchyGenerator {
  protected getFirstLevel(): HierarchyNode[] {
    const d = _.chain(GreeningRecord.all())
      .filter(r => this.getValueForRecord(r))
      .groupBy("geoAreaLabel")
      .map((regionRecords, region) => ({
        id: region,
        name: region,
        data: getGroupData(regionRecords),
        children: _.map(regionRecords, (record: GreeningRecord) => ({
          id: record.id,
          amount: this.getValueForRecord(record),
          name: record.building,
          data: getRecordData(record),
        })),
      }))
      .value();
    return d;
  }
}

export class SingleDepartmentHierarchyGenerator extends RecordBasedHierarchyGenerator {
  department: string;
  constructor(mode: TreemapMode, department: string) {
    super(mode);
    this.department = department;
  }
  protected getFirstLevel(): HierarchyNode[] {
    const { department } = this;
    return _.chain(GreeningRecord.all())
      .filter(r => this.getValueForRecord(r))
      .filter({ department })
      .groupBy("province")
      .map((provinceRecords, province) => ({
        id: province,
        name: province,
        data: getGroupData(provinceRecords),
        children: _.map(provinceRecords, (record: GreeningRecord) => ({
          id: record.id,
          name: record.building,
          amount: this.getValueForRecord(record),
          data: getRecordData(record),
        })),
      }))
      .value();
  }
}

export class SingleGeographicalRegionHierarchyGenerator extends RecordBasedHierarchyGenerator {
  region: string;
  constructor(mode: TreemapMode, region: string) {
    super(mode);
    this.region = region;
  }
  protected getFirstLevel(): HierarchyNode[] {
    const { region } = this;
    return _.chain(GreeningRecord.all())
      .filter(r => this.getValueForRecord(r))
      .filter({ geoAreaLabel: region })
      .map(regionRecords => ({
        id: regionRecords.id,
        name: regionRecords.building,
        amount: this.getValueForRecord(regionRecords),
        data: getRecordData(regionRecords),
      }))
      .value();
  }
}

export type ProvinceTreemapDrillDownMode = "department" | "city";
export class SingleProvinceHierarchyGenerator extends RecordBasedHierarchyGenerator {
  province: string;
  drillDownMode: ProvinceTreemapMode;
  constructor(
    mode: TreemapMode,
    province: string,
    drillDownMode: ProvinceTreemapDrillDownMode
  ) {
    super(mode);
    this.province = province;
    this.drillDownMode = drillDownMode;
  }
  protected getFirstLevel() {
    const { province, drillDownMode } = this;
    const x = _.chain(GreeningRecord.all())
      .filter(r => this.getValueForRecord(r))
      .filter({ province })
      .groupBy(drillDownMode)
      .map((records: GreeningRecord[], provOrDept) => ({
        name: provOrDept,
        id: provOrDept,
        data: getGroupData(records),
        children: records.map(record => ({
          name:
            drillDownMode === "department"
              ? record.building
              : `${record.building} (${record.department})`,
          id: record.id,
          amount: this.getValueForRecord(record),
          data: getRecordData(record),
        })),
      }))
      .value();
    return x;
  }
}

const getNodeHtmlFunc =
  (mode: TreemapMode) =>
  (nodeContainer: { data: HierarchyNode }, width: number, height: number) => {
    const node = nodeContainer.data;
    const textSizeClsSuffix = getTextSizeClass(width, height);
    const unit = mode === "ghg" ? "tCO<sub>2</sup>" : "GJ";

    return `
    <div class="TreeMapNode__ContentBox TreeMapNode__ContentBox--standard">
      <div 
        class="TreeMapNode__ContentTitle TreeMapNode__ContentTitle${textSizeClsSuffix}"
      >
        ${node.name}
      </div>
      <div>
        ${formats.big_int(node.amount, { raw: true })} ${unit}
      </div>
    </div>
    `;
  };

const getGhgNodeHtml = getNodeHtmlFunc("ghg");
const getEnergyNodeHtml = getNodeHtmlFunc("energy");

function getTooltipHtml(node: HierarchyNode) {
  const nodeData = node.data;
  // if (type === "smaller_group") {
  if (!nodeData) {
    return `<div class="HoverLabel">${tm("smaller_items")}</div>`;
  }

  return `
        <div class="HoverLabel">
          <div>
            ${node.name}
          </div>
          <div>
            ${formats.big_int(nodeData.totalGhg, { raw: true })} tCO<sub>2</sub>
          </div>
          <div>
          ${formats.big_int(nodeData.totalEnergy, { raw: true })} GJ
          </div>
          <div>
            ${formats.big_int(nodeData.area, { raw: true })} m<sup>2</sup>
          </div>
        </div>
        `;
}

export function CommonTreemap(props: {
  data: HierarchyNode;
  mode: TreemapMode;
}) {
  const htmlForNode = props.mode === "ghg" ? getGhgNodeHtml : getEnergyNodeHtml;
  return (
    <WrappedTreeMap
      data={props.data}
      tooltipHtmlForNode={getTooltipHtml}
      htmlForNode={htmlForNode}
      colorForNode={getPrimaryColor}
    />
  );
}
