import { ResponsiveBar } from "@nivo/bar";

import {
  useContext,
  useState,
  createContext,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { useMatch, useNavigate } from "react-router";
import Select from "react-select";

import Panel from "components/Panel";

import GreeningRecord from "subjects/GreeningRecord";
import { hrefWithLang, useToggle } from "utils";

import {
  CommonTreemap,
  SingleGeographicalRegionHierarchyGenerator,
  TreemapMode,
} from "./hierarchies";
import {
  commonBarProps,
  getBarChartHeight,
  GigaJoulesPerSqmBarTooltip,
  KgCo2PerSqmTooltip,
  TonsCo2BarTooltip,
} from "./utils";

function getRegionChoices(): string[] {
  return _.chain(GreeningRecord.all())
    .map(r => r.geoAreaLabel)
    .uniq()
    .sortBy(p => p.toLowerCase())
    .sortBy(p => p.replace("/", " "))
    .value();
}

function decodeRegion(target: string): string {
  return target.replace(/\W/g, "");
}

interface RegionDashboardState {
  region?: string;
}
const RegionDashboardContext = createContext<RegionDashboardState>();

export function RegionDashboardProvider({ children }: { children: ReactNode }) {
  // const x = useParams();
  // const { region } = x;
  const santizedRegion = useMatch(hrefWithLang("/dashboard/region/:region"))
    ?.params?.region;

  const region = getRegionChoices().find(
    p => decodeRegion(p) === santizedRegion
  );
  const provided = { region };

  return (
    <RegionDashboardContext.Provider value={provided}>
      {children}
    </RegionDashboardContext.Provider>
  );
}

export function useRegionDashboardState() {
  const { region } = useContext(RegionDashboardContext);

  const navigate = useNavigate();
  const setRegion = useCallback(
    (region?: string) =>
      navigate(hrefWithLang(`/dashboard/region/${decodeRegion(region) || ""}`)),
    [navigate]
  );
  return {
    region,
    setRegion,
  };
}

function RegionSelection() {
  const { region, setRegion } = useRegionDashboardState();
  const allRegions = useMemo(
    () => _.map(getRegionChoices(), d => ({ value: d, label: d })),
    []
  );

  return (
    <div>
      <Select
        value={_.find(allRegions, { value: region })}
        onChange={option => setRegion(option?.value)}
        options={allRegions}
        placeholder={
          region ? tdt("Select another region") : tdt("Select a region")
        }
        isClearable
      />
    </div>
  );
}

export function RegionTab() {
  return (
    <RegionDashboardProvider>
      <div>
        <RegionSelection />
        <RegionTabContent />
      </div>
    </RegionDashboardProvider>
  );
}

function RegionTabContent() {
  const { region } = useRegionDashboardState();
  if (!region) {
    return (
      <div>
        <Panel header={tdt("Select a region")}>
          <p>{tdt("Select a region to get started")}</p>
        </Panel>
      </div>
    );
  }
  return (
    <div>
      <BuildingsGhg />
      <BuildingsEnergy />
      <Treemap />
      <HistoricalGhg />
    </div>
  );
}

function BuildingsGhg() {
  const { region } = useRegionDashboardState();

  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const barData = useMemo(() => {
    return _.chain(GreeningRecord.all())
      .filter({ geoAreaLabel: region })
      .filter("totalGhg")
      .filter(shouldUseSqm ? r => r.area > 0 : r => true)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? (1000 * r.totalGhg) / r.area : r.totalGhg,
        id: r.building,
        label: r.building,
      }))
      .sortBy("value")
      .reverse()
      .value();
  }, [region, shouldUseSqm]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  return (
    <Panel header={tdt("GHG By building (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>

      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={
            shouldUseSqm
              ? value => `${value} kgCO₂ / m²`
              : value => `${value} tCO₂`
          }
          axisBottom={{
            legend: shouldUseSqm
              ? tdt("GHG Emissions (kgCO₂) per m²")
              : tdt("GHG Emissions (tCO₂)"),
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
          tooltip={shouldUseSqm ? KgCo2PerSqmTooltip : TonsCo2BarTooltip}
        />
      </div>
    </Panel>
  );
}

function BuildingsEnergy() {
  const { region } = useRegionDashboardState();

  const [shouldUseSqm, setShouldUseSqm] = useToggle(false);

  const barData = useMemo(() => {
    return _.chain(GreeningRecord.all())
      .filter({ geoAreaLabel: region })
      .filter("totalGhg")
      .filter(shouldUseSqm ? r => r.area > 0 : r => true)
      .map(r => ({
        ...r,
        value: shouldUseSqm ? r.totalEnergy / r.area : r.totalEnergy,
        id: r.building,
        label: r.building,
      }))
      .sortBy("value")
      .reverse()
      .value();
  }, [region, shouldUseSqm]);

  const height = useMemo(
    () => getBarChartHeight(barData.length),
    [barData.length]
  );

  return (
    <Panel header={tdt("Energy consumption by building (2019)")}>
      <label className="ms-4">
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={shouldUseSqm}
          onChange={setShouldUseSqm}
        />
        {tdt("Normalize by building space")} (m<sup>2</sup>)
      </label>

      <div style={{ height }}>
        <ResponsiveBar
          {...commonBarProps}
          data={barData}
          valueFormat={
            shouldUseSqm
              ? value => `${value} kgCO₂ / m²`
              : value => `${value} tCO₂`
          }
          axisBottom={{
            legend: shouldUseSqm ? tdt("GJ per m²") : tdt("GJ"),
            legendOffset: 50,
            legendPosition: "middle",
          }}
          label={null}
          tooltip={
            shouldUseSqm
              ? GigaJoulesPerSqmBarTooltip
              : GigaJoulesPerSqmBarTooltip
          }
        />
      </div>
    </Panel>
  );
}

function HistoricalGhg() {
  return <Panel header={tdt("GHG Emissions Trend")}>TODO</Panel>;
}

function Treemap() {
  const { region } = useRegionDashboardState();
  const [mode, setMode] = useState<TreemapMode>("ghg");
  const data = useMemo(() => {
    return new SingleGeographicalRegionHierarchyGenerator(
      mode,
      region as string
    ).getRoot();
  }, [mode, region]);

  const onChange = useCallback(
    e => {
      setMode(e.target.value);
    },
    [setMode]
  );

  return (
    <Panel header="Treemap: Regions (2019)">
      <select className="form-control mb-3" value={mode} onChange={onChange}>
        <option value="ghg">{tdt("GHG emissions")}</option>
        <option value="energy">{tdt("Energy consumed")}</option>
      </select>
      <CommonTreemap data={data} mode={mode} />
    </Panel>
  );
}
