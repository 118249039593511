import { GreeningRecord } from "./GreeningRecord";

interface ThreeYearDatum {
  id: string;
  department: string;
  building: string;
  eui: [number?, number?, number?];
  ghg: [number?, number?, number?];
}

class ThreeYearRecord {
  private static _all: { [id: string]: ThreeYearRecord } = {};
  constructor(obj: ThreeYearDatum) {
    Object.assign(this, obj);
    ThreeYearRecord._all[obj.id] = this;
  }

  get greeningRecord() {
    return GreeningRecord.getById(this.id);
  }

  static all() {
    return _.values(this._all);
  }
  static getById(id: string) {
    return this._all[id];
  }
}
interface ThreeYearRecord extends ThreeYearDatum {}
export { ThreeYearRecord };
export default ThreeYearRecord;
window.ThreeYearRecord = ThreeYearRecord;
